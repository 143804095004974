<template>
  <div class="section-container signin">
    <v-container>
      <v-row class="signin" justify="center">
        <v-col cols="12" md="6" class="right text-center">
          <img
            src="@/assets/images/Full-Logo-Gold.png"
            class="site-logo mb-5"
          />
          <div class="inner">
            <h2 class="text-center mb-5">Reset Password</h2>
            <validation-observer ref="resetObserver" v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(resetPassword)">
                <v-row>
                  <v-col cols="12" sm="12" v-if="message !== null">
                    <v-alert :type="type">{{ message }}</v-alert>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Password"
                      rules="required|password"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        :error-messages="errors"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="password"
                        class="form-control"
                        placeholder="New Password"
                        :type="show1 ? 'text' : 'password'"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" md="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Confirm Password"
                      :rules="{ required: true, confirmed: password }"
                    >
                      <v-text-field
                        :hide-details="!errors.length"
                        :error-messages="errors"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        v-model="confirmPassword"
                        class="form-control"
                        placeholder="Confirm Password"
                        :type="show2 ? 'text' : 'password'"
                        required
                        outlined
                        dark
                        background-color="#f0f1f6"
                        color="#000"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </validation-provider>
                    <div
                      class="forgot-password my-3 text-right font-weight-bold"
                    >
                      <router-link to="/forgot-password"
                        >Forgot password?</router-link
                      >
                    </div>
                  </v-col>
                </v-row>
                <div class="text-center">
                  <v-btn
                    class="signin-btn"
                    type="submit"
                    primary
                    dark
                    rounded
                    :disabled="loading"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Footer from "@/components/Footer.vue";
import UserService from "@/services/UserService";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Footer,
  },
  data() {
    return {
      loading: false,
      message: null,
      show1: false,
      show2: false,
      type: null,
      password: null,
      confirmPassword: null,
    };
  },
  created() {
    if (this.$route.query.token !== undefined) {
      UserService.verifyToken(this.$route.query.token)
        .then(() => {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Token Verified Successfully",
              type: "success",
            },
            { root: true }
          );
        })
        .catch((error) => {
          this.message = error.response.data.errorMessage;
          this.type = "error";
        });
    }
  },
  methods: {
    resetPassword() {
      this.loading = true;
      this.message = null;
      this.type = null;
      UserService.resetPassword({
        resetCode: this.$route.query.token,
        newPassword: this.password,
        confirmPassword: this.confirmPassword,
      })
        .then(() => {
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Password Reset Successfully",
              type: "success",
            },
            { root: true }
          );
          this.$refs.resetObserver.reset();
          this.$router.push("/Login");
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: error.response.data.errorMessage,
              type: "error",
              status: error.response.status,
            },
            { root: true }
          );
        });
    },
  },
};
</script>
